import React, { useEffect } from 'react';
import {
  Spinner, Badge,
  Box,
  Center, 
  Card, CardHeader, CardBody, Heading
} from '@chakra-ui/react';
import { useAppStateStore } from "./AppStateProvider";
import {loadStripe} from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';

const stripePromise = loadStripe('pk_live_zLpvgCMFCrwHNWf2gOxn4VkO');
// const stripePromise = loadStripe('pk_test_4FRgZ3eCZudNOTauamI6Ut0u');

function Checkout() {
  const { appState, setAppState } = useAppStateStore();

  useEffect(() => {
    const total    = parseFloat(appState.cart.chosenRate.cost) + parseFloat(appState.cart.subtotal);
    const totalInt = parseInt(total * 100);
    
    setAppState({ ...appState, loading: true, session: ''});
    // banned endpoint, the others are same as mbx
    fetch('https://dfqmautr24.execute-api.us-east-1.amazonaws.com/1/session', {
      method : 'PUT',
      body   : JSON.stringify({
        amount  : totalInt, 
        token   : appState.token,
        ship_id : appState.cart.shipment_id
      })
    })
    .then((res) => {
      res.json().then((data) => {
        if ('OK' === data.status) {
          setAppState({ 
            ...appState, 
            loading : false, 
            session : data['session'],
          });
        }
        else {
          setAppState({ ...appState, loading: false, });
          alert('Error processing data. Please try again.');
        }
      })
    })
    .catch((err) => {
      console.error(err);
      setAppState({ ...appState, loading: false, });
      alert('error');
    });    
  }, []);
  
  if (appState.loading || !appState.session) {
    return (
      <Center>
        <Spinner
          thickness='4px'
          speed='0.65s'
          emptyColor='gray.200'
          color='blue.500'
          size='xl'
        />      
      </Center>
    );
  }
  else {
    const fetchClientSecret = () => {
      return appState.session.client_secret;
    };

    const options = {fetchClientSecret};

    return (
      <Box
        display="flex"
        alignItems='top'
        justifyContent='center'
        width='100%'
        height='100%'
        pT='50'
        mT='0'
        minH='1000'
        bgGradient="linear(to-b, white, #cccccc)"
      >
        <Card  width={{base: '90%', lg: "50%"}}>
          <CardHeader>
            <Heading size='md'>Payment Processing Details</Heading>
          </CardHeader>
        
          <CardBody>
            <EmbeddedCheckoutProvider
              stripe={stripePromise}
              options={options}
            >
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>          
          </CardBody>
          {
            (appState.cart.savings > 0) &&
            <Badge colorScheme='green'>You Saved ${appState.cart.savings.toFixed(2)} by Bundeling!</Badge>
          }
        </Card>
      </Box>
    );
  }
}

export default Checkout;
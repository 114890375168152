import { createContext, useState, useContext } from "react";

const AppStateStore = createContext();
export const useAppStateStore = () => useContext(AppStateStore);

export const emptyCart = { 
  books      : [],
  subtotal   : 0.0,
  savings    : 0.0,
  update     : '',
  shipTo     : {
    name       : '',
    street1    : '',
    street2    : '',
    city       : '',
    state      : '',
    zip        : '',
    email      : '',
    isValid    : false,   
    open       : false, 
    loading    : false,
    message    : '',
    address_id : '',
  },
  parcel_id  : '',
  rates      : [],
  chosenRate : { cost: 0, },
  checkout   : false,
};

export const emptyDonor = { 
  id        : '',
  name      : '',
  address   : '',
  apartment : '',
  town      : '',
  state     : '',
  zip       : '',
  email     : '',
  phone     : '',
  memory    : '',
  books     : '',
  nameInvalid  : false,
  emailInvalid : false,
  booksInvalid : false,
  loading   : false,
  message   : '',
};
  
export const initialState = {
  user          : '',
  token         : '',
  cart          : emptyCart,
  donor         : emptyDonor,
  books         : [],
  display       : [],
  missing       : [],
  search        : '',
  donationType  : '',
  donationAmt   : '',
  endpoint      : 'https://lp915p57lg.execute-api.us-east-1.amazonaws.com/4/',
  client_secret : '',
  MFC           : false,
};

export default function AppStateProvider({children}) {
  const [appState, setAppState] = useState(initialState);
  
  return (
    <AppStateStore.Provider value={{ appState, setAppState }}>
      {children}
    </AppStateStore.Provider>
  );
}